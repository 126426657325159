/**
 * common routes
 */

export default [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/Home"),
    meta: {
      title: "home",
      icon: "mdi-home-outline",
      scope: "main",
      active: false,
      keepAlive: false,
      authRequired: true,
      // showBackground: true,
    },
  },
  {
    path: "/welcome/:login?",
    name: "welcome",
    component: () => import("@/views/Welcome"),
    props: true,
    meta: {
      title: "welcome",
      icon: "mdi-human-greeting",
      keepAlive: true,
    },
  },
  {
    path: "/invoices",
    name: "invoices",
    component: () => import("@/views/invoices/InvoiceList"),
    meta: {
      title: "invoices",
      icon: "mdi-cash-multiple",
      scope: "main",
      active: false,
      keepAlive: true,
      authRequired: true,
      permissions: {
        roles: ["API-INV"],
      },
    },
  },
  {
    path: "/invoices/:invoiceId",
    name: "invoice-details",
    component: () => import("@/views/invoices/InvoiceView"),
    props: true,
    meta: {
      title: "invoice details",
      icon: "mdi-cash-outline",
      active: false,
      keepAlive: false,
      authRequired: true,
      permissions: {
        roles: ["API-INV"],
      },
    },
  },
  {
    path: "/products",
    name: "products",
    component: () => import("@/views/products/ProductsList"),
    meta: {
      title: "products",
      icon: "mdi-text-box-multiple-outline",
      scope: "main",
      active: false,
      keepAlive: true,
      authRequired: true,
      permissions: {
        roles: ["API-PROD"],
      },
    },
  },
  {
    path: "/products/:productId",
    name: "product-details",
    component: () => import("@/views/products/ProductView"),
    props: true,
    meta: {
      title: "product details",
      icon: "mdi-text-box-multiple",
      active: false,
      keepAlive: false,
      authRequired: true,
      permissions: {
        roles: ["API-PROD"],
      },
    },
  },
  {
    path: "/customers",
    name: "reseller-customers",
    component: () => import("@/views/ResellerCustomers"),
    meta: {
      title: "customers",
      icon: "mdi-account-multiple",
      scope: "main",
      mandant: "iway",
      active: true,
      keepAlive: true,
      authRequired: true,
      permissions: {
        partnerTypes: ["PAR"],
      },
    },
  },
  {
    path: "/customers/:customerId",
    name: "reseller-products",
    component: () => import("@/views/ResellerProducts"),
    props: true,
    meta: {
      title: "products",
      active: false,
      keepAlive: true,
      authRequired: true,
      mandant: "iway",
      permissions: {
        partnerTypes: ["PAR"],
      },
    },
  },
  {
    path: "/services",
    name: "services",
    component: () => import("@/views/Services"),
    redirect: { name: "home" },
    meta: {
      title: "services",
      icon: "mdi-folder-star-multiple-outline",
      scope: "main",
      active: false,
      keepAlive: true,
    },
    children: [
      {
        path: "internet",
        name: "internet-service",
        component: () => import("@/views/services/Internet"),
        meta: {
          title: "internet",
          icon: "mdi-ip-network",
          scope: "main",
          mandant: "iway",
          active: false,
          keepAlive: true,
          authRequired: true,
          permissions: {
            partnerTypes: ["WHO"],
            roles: ["API-INT"],
          },
        },
      },
      {
        path: "bbcs",
        name: "bbcs-services",
        mandant: "iway",
        redirect: { name: "home" },
      },
      {
        path: "metronet",
        name: "metronet-services",
        mandant: "iway",
        redirect: { name: "home" },
      },
      {
        path: "bbcs/:bbcsProductId",
        name: "bbcs-details",
        component: () => import("@/components/services/bbcs/BbcsDetails"),
        props: true,
        meta: {
          title: "Swisscom (BBCS)",
          icon: "mdi-ip-network",
          mandant: "iway",
          active: false,
          keepAlive: false,
          authRequired: true,
          permissions: {
            partnerTypes: ["WHO"],
            roles: ["API-INT"],
          },
        },
      },
      {
        path: "metronet/:metronetProductId",
        name: "metronet-details",
        component: () =>
          import("@/components/services/metronet/MetronetDetails"),
        props: true,
        meta: {
          title: "Metronet",
          icon: "mdi-ip-network",
          mandant: "iway",
          active: false,
          keepAlive: false,
          authRequired: true,
          permissions: {
            partnerTypes: ["WHO"],
            roles: ["API-INT"],
          },
        },
      },
      {
        path: "reverse-dns",
        name: "reverse-dns-service",
        component: () => import("@/views/services/ReverseDns"),
        props: true,
        meta: {
          title: "reverse DNS",
          icon: "mdi-dns-outline",
          scope: "main",
          active: false,
          keepAlive: false,
          authRequired: true,
          permissions: {
            tags: ["reverse-dns"],
            roles: ["API-RDNS"],
          },
        },
      },
      {
        path: "domains",
        name: "domain-service",
        component: () => import("@/views/services/DomainRegistry"),
        props: true,
        meta: {
          title: "domains",
          icon: "mdi-domain",
          mandant: "iway",
          scope: "main",
          active: false,
          keepAlive: false,
          authRequired: true,
          permissions: {
            tags: ["registry-dns"],
            roles: ["API-DNS"],
          },
        },
      },
      {
        path: "domains/:domainId",
        name: "domain-service-details",
        component: () => import("@/views/services/DomainRegistryDetails"),
        props: true,
        meta: {
          title: "domain details",
          icon: "mdi-domain",
          mandant: "iway",
          active: false,
          keepAlive: false,
          authRequired: true,
          permissions: {
            tags: ["registry-dns"],
            roles: ["API-DNS"],
          },
        },
      },
      {
        path: "domains/:domainId/transfer-hash/:transferHash",
        name: "domain-service-details-commit-transfer-hash",
        component: () => import("@/views/services/DomainRegistryDetails"),
        props: true,
        meta: {
          title: "domain details",
          icon: "mdi-domain",
          mandant: "iway",
          active: false,
          keepAlive: false,
          authRequired: true,
          permissions: {
            tags: ["registry-dns"],
            roles: ["API-DNS"],
          },
        },
      },
      {
        path: "domains/zone/:zoneId",
        name: "domain-service-zone",
        component: () => import("@/views/services/DomainZoneDetails"),
        props: true,
        meta: {
          title: "zone details",
          icon: "mdi-domain",
          mandant: "iway",
          active: false,
          keepAlive: false,
          authRequired: true,
          permissions: {
            tags: ["forward-dns"],
            roles: ["API-DNS"],
          },
        },
      },
      {
        path: "telephony",
        name: "phone-service",
        component: () => import("@/views/services/Phone"),
        props: true,
        meta: {
          title: "telephony",
          icon: "mdi-phone",
          mandant: "iway",
          scope: "main",
          active: false,
          keepAlive: true,
          authRequired: true,
          permissions: {
            tags: ["voip", "mobile"],
            roles: ["API-VOIP", "API-MOB"],
          },
        },
      },
      {
        path: "telephony/mobile-create",
        name: "telephony-service-mobile-create",
        component: () =>
          import("@/components/services/phone/MobilePhoneCreate"),
        props: true,
        meta: {
          title: "order mobile",
          icon: "mdi-cellphone",
          mandant: "iway",
          active: false,
          keepAlive: false,
          authRequired: true,
          permissionsTagNot: true,
          permissions: {
            tags: ["psuite-tenant"],
            roles: ["API-ORD", "API-MOB"],
          },
        },
      },
      {
        path: "telephony/sip-account-create",
        name: "telephony-service-sip-account-create",
        component: () =>
          import("@/components/services/phone/SipAccountCreate"),
        props: true,
        meta: {
          title: "order VoIP",
          icon: "mdi-phone-voip",
          mandant: "iway",
          active: false,
          keepAlive: false,
          authRequired: true,
          permissionsTagNot: true,
          permissions: {
            partnerTypes: ["WHO"],
            tags: ["psuite-tenant"],
            roles: ["API-ORD", "API-VOIP"],
          },
        },
      },
      {
        path: "telephony/:productId",
        name: "phone-service-details",
        component: () => import("@/views/services/PhoneDetails"),
        props: true,
        meta: {
          title: "telephony details",
          icon: "mdi-phone",
          mandant: "iway",
          active: false,
          keepAlive: false,
          authRequired: true,
          permissions: {
            tags: ["voip", "mobile"],
            roles: ["API-VOIP", "API-MOB"],
          },
        },
      },
      {
        path: "telephony/:productId/phone/:phoneId",
        name: "telephony-service-phone-details",
        component: () => import("@/components/services/phone/PhoneBaseDetails"),
        props: true,
        meta: {
          title: "VoIP phone details",
          icon: "mdi-phone-voip",
          mandant: "iway",
          active: false,
          keepAlive: false,
          authRequired: true,
          permissions: {
            tags: ["voip"],
            roles: ["API-VOIP"],
          },
        },
      },
      {
        path: "telephony/:productId/mobile/:mobileId",
        name: "telephony-service-mobile-details",
        component: () =>
          import("@/components/services/phone/MobilePhoneDetails"),
        props: true,
        meta: {
          title: "Mobile phone details",
          icon: "mdi-cellphone",
          mandant: "iway",
          active: false,
          keepAlive: false,
          authRequired: true,
          permissions: {
            tags: ["mobile"],
            roles: ["API-MOB"],
          },
        },
      },
      {
        path: "voip",
        name: "voip-service",
        component: () => import("@/views/services/TelephonyVoip"),
        props: true,
        meta: {
          title: "new VoIP",
          icon: "mdi-phone",
          mandant: "iway",
          scope: "main",
          active: false,
          keepAlive: true,
          authRequired: true,
          permissions: {
            tags: ["voip-new"],
            roles: ["API-VOIP"],
          },
        },
      },
      {
        path: "voip/:productId",
        name: "voip-service-details",
        component: () => import("@/views/services/TelephonyVoipDetails"),
        props: true,
        meta: {
          title: "VoIP details",
          icon: "mdi-phone",
          mandant: "iway",
          active: false,
          keepAlive: false,
          authRequired: true,
          permissions: {
            tags: ["voip"],
            roles: ["API-VOIP"],
          },
        },
      },
      {
        path: "power",
        name: "power-service",
        component: () => import("@/views/services/Power"),
        meta: {
          title: "power",
          icon: "mdi-flash",
          scope: "main",
          mandant: "iway",
          active: false,
          keepAlive: false,
          authRequired: true,
          permissions: {
            tags: ["power"],
            roles: ["API-PWR"],
          },
        },
      },
      {
        path: "backup",
        name: "veeam-backup-service",
        component: () => import("@/views/services/VeeamBackup"),
        meta: {
          title: "backup",
          icon: "mdi-backup-restore",
          scope: "main",
          mandant: "iway",
          active: false,
          keepAlive: false,
          authRequired: true,
          permissions: {
            tags: ["backup"],
            roles: ["API-BCK"],
          },
        },
      },
      {
        path: "backup/:backupProductId",
        name: "veeam-backup-details",
        component: () => import("@/views/services/VeeamBackupDetails"),
        props: true,
        meta: {
          title: "Veeam backup details",
          icon: "mdi-text-box-multiple",
          active: true,
          mandant: "iway",
          keepAlive: false,
          authRequired: true,
          permissions: {
            tags: ["backup"],
            roles: ["API-BCK"],
          },
        },
      },
      {
        path: "traffic-stats",
        name: "rzo-traffic-stats",
        component: () => import("@/views/services/RzoTrafficStats"),
        props: true,
        meta: {
          title: "traffic stats",
          icon: "mdi-chart-areaspline",
          scope: "main",
          mandant: "rzo",
          active: true,
          keepAlive: false,
          authRequired: true,
          permissions: {
            tags: ["if-stats"],
            roles: ["API-IFSTAT"],
          },
        },
      },
      {
        path: "power-stats",
        name: "rzo-power-stats",
        component: () => import("@/views/services/RzoPowerStats"),
        props: true,
        meta: {
          title: "power stats",
          icon: "mdi-flash",
          scope: "main",
          mandant: "rzo",
          active: false,
          keepAlive: false,
          authRequired: true,
          permissions: {
            tags: ["power"],
            roles: ["API-PWR"],
          },
        },
      },
      {
        path: "access-logs",
        name: "rzo-access-logs",
        component: () => import("@/views/services/RzoAccessLogs"),
        props: true,
        meta: {
          title: "access logs",
          icon: "mdi-door-closed-lock",
          scope: "main",
          mandant: "rzo",
          active: true,
          keepAlive: false,
          authRequired: true,
          permissions: {
            tags: ["acs-logs", "acs-logs-rt"],
            roles: ["API-ACSLOG"],
          },
        },
      },
      {
        path: "tv",
        name: "zuma-tv",
        component: () => import("@/views/services/ZumaTv"),
        props: true,
        meta: {
          title: "TV",
          icon: "mdi-television-box",
          scope: "main",
          mandant: "iway",
          active: false,
          keepAlive: false,
          authRequired: true,
          permissions: {
            tags: ["tv"],
            partnerTypes: ["WHO"],
            roles: ["API-TV"],
          },
        },
      },
      {
        path: "tv/:productId",
        name: "zuma-tv-details",
        component: () => import("@/views/services/ZumaTvDetail"),
        props: true,
        meta: {
          title: "TV details",
          mandant: "iway",
          active: false,
          keepAlive: false,
          authRequired: true,
          permissions: {
            tags: ["tv"],
            partnerTypes: ["WHO"],
            roles: ["API-TV"],
          },
        },
      },
      {
        path: "digital-tv",
        name: "zuma-tv-end",
        component: () => import("@/views/services/ZumaTvEnd"),
        props: true,
        meta: {
          title: "TV",
          icon: "mdi-television-box",
          scope: "main",
          mandant: "iway",
          active: false,
          keepAlive: false,
          authRequired: true,
          permissions: {
            tags: ["tv"],
            partnerTypes: ["END", "PAR"],
            roles: ["API-TV"],
          },
        },
      },
      {
        path: "ip-addresses/tenants",
        name: "ip-addresses-tenants",
        component: () => import("@/views/services/IpAddressesTenants"),
        meta: {
          title: "IP addresses",
          icon: "mdi-ip",
          scope: "main",
          mandant: "iway",
          active: true,
          keepAlive: true,
          authRequired: true,
          permissions: {
            // isStaff: true,
            tags: ["ftth-tenant"],
            partnerTypes: ["WHO"],
            roles: ["API-IP"],
            // customerNumbers: [302270, 316517], // EWB, EWM
          },
        },
      },
      {
        path: "oob",
        name: "oob",
        component: () => import("@/views/services/Oob"),
        meta: {
          title: "out of band",
          icon: "mdi-server-security",
          scope: "main",
          mandant: "iway",
          active: true,
          keepAlive: true,
          authRequired: true,
          permissions: {
            tags: ["oob"],
            roles: ["API-OOB"],
          },
        },
      },
    ],
  },
  {
    path: "/orders",
    name: "orders",
    component: () => import("@/views/Orders"),
    redirect: { name: "home" },
    meta: {
      title: "orders",
      icon: "mdi-cart-outline",
      scope: "main",
      mandant: ["iway"],
      active: false,
      keepAlive: true,
    },
    children: [
      {
        path: "internet",
        name: "internet-order",
        component: () => import("@/views/orders/Internet"),
        meta: {
          title: "order internet",
          icon: "mdi-ip-network",
          scope: "main",
          mandant: "iway",
          active: false,
          keepAlive: false,
          authRequired: true,
          permissions: {
            partnerTypes: ["WHO"],
            roles: ["API-ORD"],
          },
        },
      },
      {
        path: "voip",
        name: "voip-order",
        redirect: { name: "telephony-service-sip-account-create", replace: true },
        meta: {
          title: "order VoIP",
          icon: "mdi-phone-voip",
          scope: "main",
          mandant: "iway",
          active: false,
          keepAlive: false,
          authRequired: true,
          permissionsTagNot: true,
          permissions: {
            partnerTypes: ["WHO"], 
            tags: ["psuite-tenant"],
            roles: ["API-ORD", "API-VOIP"],
          },
        },
      },
      {
        path: "mobile",
        name: "mobile-order",
        redirect: { name: "telephony-service-mobile-create", replace: true },
        meta: {
          title: "order mobile",
          icon: "mdi-cellphone",
          scope: "main",
          mandant: "iway",
          active: false,
          keepAlive: false,
          authRequired: true,
          permissionsTagNot: true,
          permissions: {
            // partnerTypes: ["WHO", "PAR"], // TODO: Remove when idcheck is implemented 
            tags: ["psuite-tenant"],
            roles: ["API-ORD", "API-MOB"],
          },
        },
      },
      {
        path: "hardware",
        name: "hardware-list",
        component: () => import("@/views/orders/HardwareShop"),
        props: true,
        meta: {
          title: "hardware shop",
          icon: "mdi-devices",
          scope: "main",
          mandant: "iway",
          active: true,
          keepAlive: true,
          authRequired: true,
          permissions: {
            roles: ["API-ORD"],
          },
        },
      },
      {
        path: "hardware/cart",
        name: "hardware-cart",
        component: () =>
          import("@/components/orders/hardware/HardwareShopCart"),
        props: true,
        meta: {
          title: "hardware cart",
          icon: "mdi-devices",
          mandant: "iway",
          active: true,
          keepAlive: true,
          authRequired: true,
          permissions: {
            roles: ["API-ORD"],
          },
        },
      },
      {
        path: "hardware/order",
        name: "hardware-order",
        component: () =>
          import("@/components/orders/hardware/HardwareShopOrder"),
        props: true,
        meta: {
          title: "hardware order",
          icon: "mdi-devices",
          mandant: "iway",
          active: true,
          keepAlive: true,
          authRequired: true,
          permissions: {
            roles: ["API-ORD"],
          },
        },
      },
      {
        path: "hardware/:itemId",
        name: "hardware-details",
        component: () =>
          import("@/components/orders/hardware/HardwareShopDetails"),
        props: true,
        meta: {
          title: "hardware details",
          icon: "mdi-text-box-multiple",
          mandant: "iway",
          active: true,
          keepAlive: true,
          authRequired: true,
          permissions: {
            roles: ["API-ORD"],
          },
        },
      },
      {
        path: "gfo-internet",
        name: "gfo-internet-checker",
        component: () => import("@/views/orders/ResellerGfoChecker"),
        meta: {
          title: "Reseller GFO orders",
          icon: "mdi-bookmark-outline",
          scope: "main",
          mandant: ["iway"],
          active: true,
          keepAlive: false,
          authRequired: true,
          permissions: {
            roles: ["API-ORD"],
            partnerTypes: ["END", "PAR"],
          },
        },
      },
      {
        path: "gfo-internet/checkout",
        name: "gfo-internet-checkout",
        component: () => import("@/views/orders/ResellerGfoCheckout"),
        meta: {
          title: "GFO Checkout",
          icon: "mdi-bookmark-outline",
          mandant: ["iway"],
          active: true,
          keepAlive: false,
          authRequired: true,
          permissions: {
            roles: ["API-ORD"],
            partnerTypes: ["END", "PAR"],
          },
        },
      },
      {
        path: "gfo_orders",
        name: "gfo-orders",
        component: () => import("@/views/orders/GfoOrders"),
        meta: {
          title: "GFO orders",
          icon: "mdi-bookmark-outline",
          scope: "main",
          mandant: "iway",
          active: true,
          keepAlive: false,
          authRequired: true,
          permissions: {
            roles: ["API-ORD"],
            customerNumbers: [10000, 307612], // SAK, iWay only
          },
        },
      },
      {
        path: "3cx-licenses",
        name: "License3cx",
        component: () => import("@/views/orders/3cxLicenses"),
        props: true,
        meta: {
          title: "order 3cx licenses",
          icon: "mdi-phone-in-talk",
          scope: "main",
          mandant: "iway",
          active: true,
          keepAlive: false,
          authRequired: true,
          permissions: {
            tags: ["3cx"],
            roles: ["API-3CX"],
          },
        },
      },
      {
        path: "tv",
        name: "zuma-tv-order",
        component: () => import("@/views/services/ZumaTvDetail"),
        props: true,
        meta: {
          title: "order TV",
          icon: "mdi-television-box",
          scope: "main",
          mandant: "iway",
          active: false,
          keepAlive: false,
          authRequired: true,
          permissions: {
            tags: ["tv"],
            partnerTypes: ["WHO"],
            roles: ["API-ORD"],
          },
        },
      },
      {
        path: "net-backup",
        name: "net-backup-service",
        component: () => import("@/views/services/NetBackupList"),
        meta: {
          title: "Net backup 2.0",
          icon: "mdi-backup-restore",
          scope: "main",
          mandant: "iway",
          active: false,
          keepAlive: true,
          authRequired: true,
          permissions: {
            isStaff: true,
            tags: ["netbackup"],
            roles: ["API-BCK"],
          },
        },
      },
      {
        path: "net-backup/:productId",
        name: "net-backup-service-details",
        component: () => import("@/views/services/NetBackupDetails"),
        props: true,
        meta: {
          title: "Net backup 2.0",
          icon: "mdi-backup-restore",
          mandant: "iway",
          active: false,
          keepAlive: false,
          authRequired: true,
          permissions: {
            isStaff: true,
            tags: ["netbackup"],
            roles: ["API-BCK"],
          },
        },
      },
      {
        path: "gfo-offer/:netz_type",
        name: "gfo-offer-loading",
        component: () => import("@/views/orders/ResellerGfoNetzLoading"),
        props:true,
        meta: {
          title: "Loading",
          icon: "mdi-bookmark-outline",
          mandant: ["iway"],
          active: false,
          keepAlive: false,
          authRequired: true,
          permissions: {
            roles: ["API-ORD"],
            partnerTypes: ["END", "PAR", "WHO"],
          },
        },
      },
      {
        path: "gfo-offer/phone",
        name: "gfo-offer-phone",
        props: {netz_type: "phone"},
        component: () => import("@/views/orders/ResellerGfoNetzOverview"),
        meta: {
          title: "Telefonie",
          icon: "mdi-phone",
          scope: "main",
          mandant: ["iway"],
          active: false,
          keepAlive: false,
          authRequired: true,
          permissions: {
            roles: ["API-ORD-PHONE"],
            partnerTypes: ["END", "PAR", "WHO"],
          },
        },
      },
      {
        path: "gfo-offer/tv",
        name: "gfo-offer-tv",
        component: () => import("@/views/orders/ResellerGfoNetzOverview"),
        props: {netz_type: "tv"},
        meta: {
          title: "TV",
          icon: "mdi-television-box",
          scope: "main",
          mandant: ["iway"],
          active: false,
          keepAlive: false,
          authRequired: true,
          permissions: {
            roles: ["API-ORD-TV"],
            partnerTypes: ["END", "PAR", "WHO"],
          },
        },
      },
      {
        path: "gfo-offer/mobile",
        name: "gfo-offer-mobile",
        component: () => import("@/views/orders/ResellerGfoNetzOverview"),
        props: {netz_type: "mobile"},
        meta: {
          title: "Data-SIM",
          icon: "mdi-sim-outline",
          scope: "main",
          mandant: ["iway"],
          active: false,
          keepAlive: false,
          authRequired: true,
          permissions: {
            roles: ["API-ORD-MOBILE"],
            partnerTypes: ["END", "PAR", "WHO"],
          },
        },
      },
      {
        path: "gfo-offer/hosting",
        name: "gfo-offer-hosting",
        component: () => import("@/views/orders/ResellerGfoNetzOverview"),
        props: {netz_type: "hosting"},
        meta: {
          title: "Hosting",
          icon: "mdi-web",
          scope: "main",
          mandant: ["iway"],
          active: false,
          keepAlive: false,
          authRequired: true,
          permissions: {
            roles: ["API-ORD-HOSTING"],
            partnerTypes: ["END", "PAR", "WHO"],
          },
        },
      },
      {
        path: "gfo-offer/domain",
        name: "gfo-offer-domain",
        component: () => import("@/views/orders/ResellerGfoNetzOverview"),
        props: {netz_type: "domain"},
        meta: {
          title: "Domains",
          icon: "mdi-domain",
          scope: "main",
          mandant: ["iway"],
          active: false,
          keepAlive: false,
          authRequired: true,
          permissions: {
            roles: ["API-ORD-DOMAIN"],
            partnerTypes: ["END", "PAR", "WHO"],
          },
        },
      },
      {
        path: "gfo-offer/gen",
        name: "gfo-offer-gen",
        component: () => import("@/views/orders/ResellerGfoNetzOverview"),
        props: {netz_type: "gen"},
        meta: {
          title: "Andere Angebote",
          icon: "mdi-bookmark-outline",
          scope: "main",
          mandant: ["iway"],
          active: false,
          keepAlive: false,
          authRequired: true,
          permissions: {
            roles: ["API-ORD-GEN"],
            partnerTypes: ["END", "PAR", "WHO"],
          },
        },
      },
      
      {
        path: "gfo/:netz_slug",
        name: "gfo-offer-checkout",
        props: true,
        component: () => import("@/views/orders/ResellerGfoCheckout"),
        meta: {
          title: "GFO Checkout",
          icon: "mdi-bookmark-outline",
          mandant: ["iway"],
          active: true,
          keepAlive: false,
          authRequired: true,
          permissions: {
            roles: ["API-ORD"],
            partnerTypes: ["END", "PAR", "WHO"],
          },
        },
      },
    ],
  },
  {
    path: "/tasks",
    name: "tasks",
    component: () => import("@/views/Tasks"),
    props: true,
    meta: {
      title: "tasks",
      icon: "mdi-folder-search-outline",
      scope: "main",
      active: true,
      keepAlive: false,
      authRequired: true,
      permissions: {
        roles: ["API-ORD"],
      },
    },
  },
  {
    path: "/tasks/:taskId",
    name: "task-details",
    component: () => import("@/views/TaskDetails"),
    props: true,
    meta: {
      title: "task details",
      icon: "mdi-text-box-multiple",
      active: true,
      keepAlive: false,
      authRequired: true,
      permissions: {
        roles: ["API-ORD"],
      },
    },
  },
  {
    path: "/docs",
    name: "doc-and-msg",
    component: () => import("@/views/msg/DocumentsList"),
    props: true,
    meta: {
      title: "documents & messages",
      titleShort: "documents",
      icon: "mdi-file-document-outline",
      scope: "main",
      active: true,
      keepAlive: true,
      authRequired: true,
    },
  },
  {
    path: "/docs/add",
    name: "doc-and-msg-add",
    component: () => import("@/views/msg/DocumentsDetails"),
    meta: {
      title: "create new messages",
      icon: "mdi-file-document-outline",
      active: true,
      keepAlive: false,
      authRequired: true,
    },
  },
  {
    path: "/docs/:messageId",
    name: "doc-and-msg-details",
    component: () => import("@/views/msg/DocumentsDetails"),
    props: true,
    meta: {
      title: "documents & messages details",
      icon: "mdi-file-document-outline",
      active: true,
      keepAlive: true,
      authRequired: true,
    },
  },
  {
    path: "/password-reset/:username?",
    name: "password-reset",
    component: () => import("@/views/PasswordReset"),
    props: true,
    meta: {
      title: "password reset",
      icon: "mdi-lock-reset",
      keepAlive: false,
      authRequired: false,
      showBackground: true,
    },
  },
  {
    path: "/password-change/:token",
    name: "password-change-email",
    component: () => import("@/views/PasswordChangeEmail"),
    props: true,
    meta: {
      title: "password change",
      icon: "mdi-lock-reset",
      keepAlive: false,
      authRequired: false,
      showBackground: true,
    },
  },
  {
    path: "/email-change/:token",
    name: "email-change-email",
    component: () => import("@/views/EmailChangeEmail"),
    props: true,
    meta: {
      title: "email change",
      icon: "mdi-email",
      keepAlive: false,
      authRequired: false,
      showBackground: true,
    },
  },
  {
    path: "/staff",
    name: "staff",
    redirect: { name: "staff-account-switch", replace: true },
    component: () => import("@/views/Staff"),
    meta: {
      title: "staff",
      icon: "mdi-account-tie-outline",
      scope: "main",
      keepAlive: true,
      authRequired: true,
      permissions: {
        isStaff: true,
      },
    },
    children: [
      {
        path: "account-switch/:customerNumber?",
        name: "staff-account-switch",
        component: () => import("@/views/staff/StaffAccountSwitch"),
        props: true,
        meta: {
          title: "customer switch",
          icon: "mdi-account-switch-outline",
          scope: "main",
          keepAlive: true,
          authRequired: true,
          permissions: {
            isStaff: true,
          },
          showBackground: true,
        },
      },
      {
        path: "access-logs",
        name: "staff-access-logs",
        component: () => import("@/views/staff/StaffAccessLogs"),
        props: true,
        meta: {
          title: "access logs",
          icon: "mdi-view-list-outline",
          scope: "main",
          keepAlive: true,
          authRequired: true,
          permissions: {
            isStaff: true,
          },
        },
      },
      {
        path: "messages",
        name: "staff-messages",
        component: () => import("@/views/staff/StaffMessages"),
        meta: {
          title: "messages",
          icon: "mdi-message-text-outline",
          scope: "main",
          keepAlive: true,
          authRequired: true,
          permissions: {
            isStaff: true,
          },
        },
      },
    ],
  },
  {
    path: "/test",
    name: "test",
    component: () => import("@/views/Test"),
    meta: {
      title: "Test",
      icon: "mdi-function",
      //scope: "main",
      active: true,
      keepAlive: false,
      authRequired: false,
      development: true,
    },
  },
  {
    path: "/test/:slug",
    name: "test-props",
    component: () => import("@/views/Test"),
    props: true,
    meta: {
      title: "Test",
      icon: "mdi-function",
      //scope: "main",
      active: true,
      keepAlive: false,
      authRequired: false,
      development: true,
    },
  },
  {
    path: "*",
    name: "not-found",
    component: () => import("@/views/NotFound"),
    meta: {
      title: "not found",
      noRedirect: false,
      authRequired: false,
      keepAlive: true,
      showBackground: true,
    },
  },
];
